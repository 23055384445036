<template>
  <div id="app">
    <error-404 />
  </div>
</template>

<script>
import { error404 } from './components/error-404';

export default {
  name: 'App',
  components: {
    error404,
  },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
