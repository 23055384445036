<template>
    <div class="error-404">
        <div class="container">
            <!-- eslint-disable max-len -->
            <div class="column1">
                <image-404 />
            </div>
            <div class="column2">
                <h1 class="text-404">404</h1>
                <p class="text-error-message">Oops… The page you are looking for has either been removed, undergone a name change, or is temporarily unavailable.</p>
                <br >
                <p class="text-error-message">Check these trending tags get you back on track.</p>
                <div class="tags">
                    <p>#BlowJob #Cosplay #Facials #Creampie #Riding #POV #JOI #Lingerie #BigBoos #Taboo</p>
                </div>
            </div>
            <!-- eslint-enable max-len -->
        </div>

    </div>
</template>

<script>
import image404 from './image404.vue';

export default {
  name: 'PageError404',
  components: {
    image404,
  },
};
</script>

<style lang="scss" scoped>
.error-404 {
    justify-content: center;
    align-items: center;
    display: flex;
    height: calc(100vh - 200px);

    .container {
        max-width: 996px;
        display: flex;

        @media screen and (max-width: 768px) {
            width: 100%;
            flex-wrap: wrap;
        }

        .column1 {
            margin-right: 48px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 768px) {
                width: 100%;
                margin-right: 0px;
                margin-bottom: 32px;
            }
        }

        .column2 {
            text-align: left;

            .text-404 {
                text-align: left;
                font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-weight: bold;
                line-height: 94px;
                font-size: 80px;
                font-style: normal;
                letter-spacing: 0px;
                color: #101010;
                opacity: 1;
                height: 94px;

                @media screen and (max-width: 768px) {
                    text-align: center;
                }
            }

            .text-error-message {
                padding-bottom: 48px;
                text-align: left;
                font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-weight: normal;
                line-height: 28px;
                font-size: 24px;
                font-style: normal;
                letter-spacing: 0px;
                color: #404040;
                opacity: 1;

                @media screen and (max-width: 768px) {
                    text-align: center;
                }
            }

            .tags {
              color: #B53BFE;
              text-align: left;
              font: normal normal normal 14px/22px 'Roboto';
              letter-spacing: 0px;
              opacity: 1;
            }
        }
    }

    .active {
        background: linear-gradient(285deg, #4F53EE 0%, #A94AF7 100%) 0% 0% no-repeat padding-box;;
        border: none !important;
        margin: 0px;
    }
}

</style>
