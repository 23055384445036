<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="181.776"
        height="167"
        viewBox="0 0 181.776 167"
    >
        <defs>
            <linearGradient
                id="linear-gradient"
                x1="0.5"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop
                    offset="0"
                    stop-color="#b53bfe"
                />
                <stop
                    offset="0.999"
                    stop-color="#4d53f3"
                />
            </linearGradient>
        </defs>
        <g
            id="Group_8562"
            data-name="Group 8562"
            transform="translate(-559.281 -338)"
        >
            <ellipse
                id="Ellipse_11929"
                data-name="Ellipse 11929"
                cx="79.78"
                cy="59.525"
                rx="79.78"
                ry="59.525"
                transform="translate(570.623 375.203)"
                fill="#101010"
            />
            <g
                id="Group_5"
                data-name="Group 5"
                transform="translate(559.281 338)"
            >
                <!-- eslint-disable max-len -->
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M191.972,91.22c-3.189-18.123-11.465-32.539-22.611-40.636A41.828,41.828,0,0,0,156.8,27.868c-12.007-10.886-29.427-12.129-42.121-2.635-5.439,4.069-9.259,9.476-12.206,15.715l-.431,0c-1.116-2-2.046-3.787-3.1-5.49-11.311-18.265-32.906-22.083-49.815-8.83-7.4,5.8-12.428,14.938-14.263,24.7-13.584,10.525-22.79,30.475-23.278,53.5-.672,31.721,16.389,68.981,66.292,78.428A140.8,140.8,0,0,0,104,185.752h0c18.18,0,44.333-3.836,65.672-22.109C188.173,147.8,196.927,119.372,191.972,91.22ZM160.1,148.883c-18.391,11.394-40.929,13.787-56.6,13.787h0a166.558,166.558,0,0,1-22.511-1.553c-43.008-5.892-57.712-29.128-57.132-48.909a39.219,39.219,0,0,1,36.035-38.2c14.017-1.089,28.255-1.641,42.315-1.641h0c14.059,0,28.3.552,42.315,1.641a38.406,38.406,0,0,1,34.8,29.708C183.586,121.275,176.041,139,160.1,148.883Z"
                    transform="translate(-11.564 -18.752)"
                    fill="url(#linear-gradient)"
                />
                <!-- eslint-enable max-len -->
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M103.1,124.987H84.189V135.8h33.536V124.987Z"
                    transform="translate(-9.55 -15.808)"
                    fill="#fff"
                />
                <g
                    id="Group_3"
                    data-name="Group 3"
                    transform="translate(112.543 67.891)"
                >
                    <rect
                        id="Rectangle_6"
                        data-name="Rectangle 6"
                        width="24.692"
                        height="11.328"
                        transform="translate(0 18.383) rotate(-48.116)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_7"
                        data-name="Rectangle 7"
                        width="24.692"
                        height="11.328"
                        transform="matrix(0.744, 0.668, -0.668, 0.744, 7.595, 10.032)"
                        fill="#fff"
                    />
                </g>
                <g
                    id="Group_4"
                    data-name="Group 4"
                    transform="translate(44.394 67.891)"
                >
                    <rect
                        id="Rectangle_8"
                        data-name="Rectangle 8"
                        width="24.692"
                        height="11.328"
                        transform="translate(17.545 25.946) rotate(-131.884)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_9"
                        data-name="Rectangle 9"
                        width="24.692"
                        height="11.328"
                        transform="matrix(-0.744, 0.668, -0.668, -0.744, 25.946, 18.465)"
                        fill="#fff"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'Image404',
};
</script>
